export const isYoutubeUrl = (url: string) => {
  if (!url) return false;
  let regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
  let match = url.match(regExp);
  if (match && match[2].length == 11) {
    return {
      id: match[2],
    };
  }
  return false;
};
